import styles from '../../styles/SpecialityDetails.module.css'
import classnames from 'classnames'

export default function Ems () {
  return (
    <div className={classnames('mobile', styles.detail)}>
      <img src={'images/speciality/detail-30.jpg'} alt={'Squva'} />
      <div className={classnames('cover', styles.cover)}>
        <h2>EMS Airflow</h2>
      </div>
      <div className={classnames('content', styles.content)}>
        <div className={classnames('box', styles.box)}>
          <span>EMS Airflow</span>
          <p>나노 입자 파우더를 뿌려 기존 스케일러로 제거할 수 없는 치아 틈이나 치아의 미세한 구멍에 낀 각종 착색과 치태를 깨끗이 청소할 수 있습니다. 미백은 화학약품을 쓰기 때문에 치아에 자극.
            에어플로우는 치아 보존적으로 모든 부위의 치석, 치태, 착색을 제거해 치아를 깨끗하고 밝아지게 해줍니다.</p>
        </div>
      </div>
    </div>
  )
}
