import styles from '../../styles/SpecialityDetails.module.css'
import classnames from 'classnames'

export default function Medi () {
  return (
    <div className={classnames('mobile', styles.detail)}>
      <img src={'images/speciality/detail-41.jpg'} alt={'MEDIHUB IJECT'} />
      <div className={classnames('cover', styles.cover)}>
        <h2>MEDIHUB IJECT</h2>
      </div>
      <div className={classnames('content', styles.content)}>
        <div className={classnames('box', styles.box)}>
          <span>MEDIHUB IJECT</span>
          <p>치과에 가면 마취주사에 대한 공포감때문에 대부분의 사람들이 치과에 방문하는 것을 불편해합니다.
            통증을 유발하며, 부정확한 약물 주입으로 불편을 느끼는 수동형 주사기 대신에 수동형 주사기의 단점을 보완한 최신 전자식 마취기를 사용하여 마취액의 양을 미세하게 조절하여 마취액의 압력으로 인한
            환자분들의 통증을 줄여줍니다.
            기존의 수동주사기는주사침자입점 거리가 달라 적응하기 불편한 점이 있었으나 아이젝은 기존 수동주사기와의 87mm 자입점 길이 동일거리를 구현하였으며, 니들이 혈관에 들어가 마취액이 주입되었을 경우
            혈액이
            역류하여 의료사고가 발생 될 위험률이 높은 확률을 적습니다.
            니들을 통해 혈액이 유입되는 것을 확인할 수 있습니다. 또한 메탈 시린지와 동일한 금속 재질로 가공하여 멸균 후 반영구적으로도 사용이 가능합니다.</p>
        </div>
        <div className={classnames('slider', styles['medihub-inject'])}>
          <div className={styles['slide-item']}>
            <img src={'images/speciality/detail-42.jpg'} alt={'마취제 온도 조절기'} />
            <span>마취제 온도 조절기</span>
            <p>마취제의 온도를 미리 체온과 비슷하게 유지하여 온도 차이에 의한 통증을 최소화합니다.</p>
          </div>
          <div className={styles['slide-item']}>
            <img src={'images/speciality/detail-43.jpg'} alt={'도포 마취 연고'} />
            <span>도포 마취 연고</span>
            <p>바르는 마취연고를 주사 자입부위에 미리 도포하여 마취 주사 자입 시 통증을 줄여줍니다.</p>
          </div>
          <div className={styles['slide-item']}>
            <img src={'images/speciality/detail-44.jpg'} alt={'해피카인'} />
            <span>해피카인</span>
            <p>스케일링 전 가글을 통한 액체 표면 마취로 아프지 않고 편안한 스케일링을 받을 수 있게 도와줍니다.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
