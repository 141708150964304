import _ from 'lodash';
import { HashLink as Link } from "react-router-hash-link";
import styles from '../styles/Breadcrumb.module.css';

const breadcrumb = [{
  text: '길튼치과의 약속',
  link: '/promise'
}, {
  text: '치과 둘러보기',
  link: '/space'
}, {
  text: '의료진 소개',
  link: '/team'
}, {
  text: '진료시간 & 오시는 길',
  link: '/opening'
}]

export const Breadcrumb = ({ activeIndex }) => {
  return <nav className={styles.breadcrumb}>
    <ul>
      {_.map(breadcrumb, (o, i) => <li key={i}>
        <Link to={o.link} className={activeIndex === i ? styles.active : ""}>{o.text}</Link>
      </li>)}
    </ul>
  </nav>
}
