import _ from 'lodash'
import { Intro } from '../components/Intro'
import { Breadcrumb } from '../components/Breadcrumb'
import styles from '../styles/Opening.module.css'
import classnames from 'classnames'

const busInfo = [{
  'type': '간선',
  'no': [340, 461, 641]
}, {
  'type': '지선',
  'no': [3012, 3412, 4319, 4412, 4432]
}, {
  'type': '광역',
  'no': ['M5422']
}, {
  'type': '마을',
  'no': ['서초03', '서초09', '서초11', '서초21']

}, {
  'type': '직행',
  'no': [1311, 3000, 3002, 3003, 3007, 3100, 3101, 3102, '3900전', 5300, '5300-1', 8201, 9700]
}]

const subwayInfo = [{
  'type': '3호선',
  'desc': '3호선 양재역 1번 출구'
}, {
  'type': '신분당',
  'desc': '양재역 방면 1번 출구'
}]

export default function Opening () {
  return (
    <main id="hours" className="Content">
      <Intro title="진료시간"
             headline="Gilton's Opening Hours"
             image="images/opening.jpg"
             image-pc="images/opening-pc.jpg"
             description={<p>길튼치과는 예약제를 시행하고 있습니다.<br />
               미리 예약 후 내원하시면 원하는 시간에<br />
               대기시간 없이 편하게 진료 받으실 수 있습니다.</p>}
      />
      <Breadcrumb activeIndex={3} />
      <section className={classnames(styles.white, styles.hours)}>
        <h2>진료시간</h2>
        <table>
          <thead>
          <tr>
            <th>진료요일</th>
            <th>진료시간</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>월 / 화 / 금</td>
            <td><sub>AM</sub>10:00 - <sub>PM</sub>07:00</td>
          </tr>
          <tr>
            <td className="double">수요일<br /><small>야간진료</small></td>
            <td><sub>AM</sub>10:00 - <sub>PM</sub>09:00</td>
          </tr>
          <tr>
            <td>토요일</td>
            <td className="double"><sub>AM</sub>09:30 - <sub>PM</sub>02:00<br /><small>(토요일은 점심시간 없이 진료합니다.)</small>
            </td>
          </tr>
          <tr>
            <td>점심시간</td>
            <td><sub>PM</sub>01:00 - <sub>PM</sub>02:00</td>
          </tr>
          </tbody>
        </table>
        <p>목요일/일요일/공휴일은 휴진 입니다.</p>
      </section>
      <section id="locations" className={styles.locations}>
        <h2>오시는 길</h2>
        <h3>주소</h3>
        <p>- 도로명 : 서울특별시 서초구 서운로 34, 1F 길튼치과</p>
        <p>- 지번 : 서울특별시 서초구 서초동 1356-4 1F 길튼치과</p>
        <div className="btn-group">
          <a href="http://naver.me/FiLz1bdg"
             target="_blank" rel="noreferrer" className={classnames('btn', styles.btn)}>네이버 지도</a>
          <a href="https://m.map.kakao.com/actions/detailMapView?id=2144984124&refService=place"
             target="_blank" rel="noreferrer" className={classnames('btn', styles.btn)}>카카오맵</a>
        </div>
        <div className={styles.map}>
          <img src={'location.png'} alt="길튼치과 약도" />
        </div>
        <h3>주변 버스 안내</h3>
        <div className={styles['info-bus']}>
          {_.map(busInfo, (o, i) => <dl key={i}>
            <dt>{o.type}</dt>
            <dd>
              <ul>
                {_.map(o.no, (n, ni) => <li key={ni}>{n}</li>)}
              </ul>
            </dd>
          </dl>)}
        </div>
        <h3>주변 지하철 안내</h3>
        <div className={styles['info-subway']}>
          {_.map(subwayInfo, (o, i) => <dl key={i}>
            <dt>{o.type}</dt>
            <dd>{o.desc}</dd>
          </dl>)}
        </div>
      </section>
    </main>
  )
}
