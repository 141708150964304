import _ from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../styles/ImageSlide.module.css';

export const ImageSlide = ({ images, speed, autoplay, autoplaySpeed, arrows, adaptiveHeight, className }) => {
  const config = {
    dots: true,
    layLoad: true,
    infinite: true,
    speed: speed || 1000,
    arrows: arrows || false,
    autoplay: autoplay || false,
    autoplaySpeed: autoplaySpeed || 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: adaptiveHeight || false
  };

  return <Slider {...config} className={className}>
    {_.map(images, (o, i) => <div key={i} className={styles['each-slide']}><img src={o} alt={`image_${i}`} /></div>)}
  </Slider>
};
