import { useState } from "react";
import { Link } from "react-router-dom";
import { Booking } from "./Booking";
import { Navigation } from "./Navigation";
import styles from '../styles/Header.module.css';

export const Header = (props) => {
  const [navShow, setNavShow] = useState(false);

  let navButton = <span onClick={() => setNavShow(!navShow)} className={styles['btn-drawer']}>Show Navigation</span>
  if (navShow) {
    navButton = <span onClick={() => setNavShow(false)} className={styles['btn-close']}>Close</span>
  }

  return <header className={styles.header}>
    <Link to="/" onClick={() => setNavShow(false)}>
      <h1 className={navShow ? styles.bright : styles.title}>Gilton Dental Clinic</h1>
    </Link>
    <Booking />
    {navButton}
    {navShow && <Navigation menu={props.menu} onClick={() => setNavShow(false)} />}
  </header>;
}
