import styles from '../styles/Intro.module.css'
import classnames from 'classnames'

export const Intro = (props) => <section id={props.id} className={styles.intro}>
  {props.image && <img src={props.image} alt={props.image} className="mobile" />}
  {props['image-pc'] && <img src={props['image-pc']} alt={props['image-pc']} className="pc" />}
  <div className={classnames('cover', styles.cover)}>
    <em>{props.headline}</em>
    <h1>{props.title}</h1>
    {props.description}
  </div>
</section>
