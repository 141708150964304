import { HashLink as Link } from 'react-router-hash-link'
import { ImageSlide } from '../components/ImageSlide'
import styles from '../styles/Home.module.css'
import classnames from 'classnames'

const mainImages = [
  'images/home-01.jpg',
  'images/home-02.jpg',
  'images/home-03.jpg',
  'images/home-04.jpg',
  'images/home-05.jpg'
]

const mainPcImages = [
  'images/home-pc-01.jpg',
  'images/home-pc-02.jpg',
  'images/home-pc-03.jpg',
  'images/home-pc-04.jpg',
  'images/home-pc-05.jpg'
]

export default function Home () {
  return (
    <main className="Content">
      <section className={styles.greeting}>
        <ImageSlide images={mainImages} autoplay className='mobile' />
        <ImageSlide images={mainPcImages} autoplay className='pc' />
        <div className={classnames('cover', styles.cover)}>
          <h1 className='mobile'>“길이길이 튼튼하게<br />
            길이 트이다”</h1>
          <h1 className='pc'>“길이길이 튼튼하게, 길이 트이다”</h1>
          <p>환자분의 자연치아와 구강을 건강하게 회복하고<br />
            길이길이 튼튼하게 유지시켜 환자분의 삶의 길이 트이는데<br />
            보탬이 되고자 하는 소망을 담았습니다.</p>
        </div>
      </section>
      <section className={styles['home-speciality']}>
        <img src={'images/home-speciality.jpg'} alt={'길튼치과의 특별함'} className='mobile' />
        <img src={'images/home-pc-speciality.jpg'} alt={'길튼치과의 특별함'} className='pc' />
        <div className={classnames('cover', styles.cover)}>
          <div className={classnames('content', styles.content)}>
            <em>Gilton’s Speciality</em>
            <h2>길튼치과의 특별함</h2>
            <ul>
              <li>1. Professional</li>
              <li>2. Prevention</li>
              <li>3. Premiere</li>
            </ul>
            <div className="btn-group">
              <Link to="/speciality" className="btn btn-fill">Show More</Link>
            </div>
          </div>
        </div>
      </section>
      <section className={styles['home-department']}>
        <img src={'images/home-department.jpg'} alt={'진료과목'} className='mobile' />
        <img src={'images/home-pc-department.jpg'} alt={'진료과목'} width={1920} className='pc' />
        <div className={classnames('cover', styles.cover)}>
          <div className="content">
            <em>Gilton’s Care</em>
            <h2>길튼치과 진료과목</h2>
            <p>임플란트, 치아교정, 소아치료,<br />
              심미치료, 잇몸치료, 충치치료, 신경치료,<br />
              사랑니발치, 턱관절치료, 틀니치료</p>
          </div>
        </div>
        <ul>
          <li><span className={styles['dep-implant']}>임플란트</span></li>
          <li><span className={styles['dep-orthodontia']}>치아교정</span></li>
          <li><span className={styles['dep-child']}>소아치료</span></li>
          <li><span className={styles['dep-beauty']}>심미치료</span></li>
          <li><span className={styles['dep-gum']}>잇몸치료</span></li>
          <li><span className={styles['dep-neuro']}>신경치료</span></li>
          <li><span className={styles['dep-wisdom']}>사랑니발치</span></li>
          <li><span className={styles['dep-jaw']}>턱관절치료</span></li>
          <li><span className={styles['dep-denture']}>틀니치료</span></li>
          <li><span className={styles['dep-preservation']}>자연치아보존</span></li>
          <li><span className={styles['dep-digital']}>디지털치료</span></li>
          <li><span className={styles['dep-decay']}>충치치료</span></li>
        </ul>
      </section>
      <section className={styles['home-space']}>
        <img src={'images/home-space.jpg'} alt={'치과 둘러보기'} className='mobile' />
        <img src={'images/home-pc-space.jpg'} alt={'치과 둘러보기'} className='pc' />
        <div className={classnames('cover', styles.cover)}>
          <div className="content">
            <em>Gilton’s Space</em>
            <h2>치과 둘러보기</h2>
            <p>‘치과는 무섭다’는 고정관념을 깨고<br />
              오시는 고객이 편안하게 진료받고<br />
              돌아가실 수 있도록 따뜻하고<br />
              자연친화적인 공간을 제공합니다.</p>
            <div className="btn-group">
              <Link to="/space" className="btn">Show More</Link>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
