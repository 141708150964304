import { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import styles from '../styles/Speciality.module.css'
import Trios from './Detail/Trios'
import Squva from './Detail/Squva'
import Ems from './Detail/Ems'
import Medi from './Detail/Medi'
import classnames from 'classnames'

const more = (isShow) => isShow ? 'Less' : 'More'

export default function Speciality () {
  const [showTrio, setShowTrio] = useState(false)
  const [showSquva, setShowSquva] = useState(false)
  const [showEms, setShowEms] = useState(false)
  const [showMedi, setShowMedi] = useState(false)

  return (
    <main className={classnames('Content', styles.speciality)}>
      <section>
        <img src={'images/speciality.jpg'} alt="특별함" className="mobile" />
        <img src={'images/speciality-pc.jpg'} alt="특별함" className="pc" />
        <div className={classnames('cover', styles.cover)}>
          <em>Gilton’s Speciality</em>
          <h1>길튼치과의 특별함</h1>
          <ul>
            <li><Link to="#professional" className={classnames('btn', styles.btn)}>Professional</Link></li>
            <li><Link to="#prevention" className={classnames('btn', styles.btn)}>Prevention</Link></li>
            <li><Link to="#premiere" className={classnames('btn', styles.btn)}>Premiere</Link></li>
          </ul>
        </div>
      </section>
      <section id="professional" className={classnames('white', styles.professional)}>
        <div className={classnames('content', styles.content)}>
          <h2 className={classnames('line-hg', styles['line-hg'])}>Professional</h2>
        </div>
        <div className={classnames('content', 'narrow', styles.content, styles.narrow)}>
          <dl>
            <dt>01</dt>
            <dd>치과보철학과 치과교정학 모두를 수학한 치과 전문의 대표원장님이 정확한 진단을 통해 치아 보존적인 방향으로 통합적인 계획을 수립합니다.</dd>
            <dt>02</dt>
            <dd>미국 명문 치과대학인 UCLA와 하버드의 치의학 커리큘럼을 수료한 대표원장님이 글로벌한 최신 진료를 제공하고 있으며 지속적인 교류 및 학술활동을 하고 있습니다.</dd>
          </dl>
          <img src={'images/speciality/1.jpg'} alt={'Professional'} />
        </div>
      </section>
      <section className={classnames('pc', styles.band)}>
        <img src={'images/speciality-1.jpg'} alt={'Prevention Promise'} />
        <p>길튼치과는 환자 입에 닿는 모든 기구를 사용 후 소독합니다.</p>
      </section>
      <section id="prevention" className={styles['speciality-prevention']}>
        <div className={classnames('content', styles.content)}>
          <h2 className={classnames('line-hg', styles['line-hg'])}>Prevention</h2>
          <img src={'images/speciality/903A1426_1.png'} alt="Prevention" className={classnames('mobile', styles.hg)} />
          <div className={classnames('mobile', 'cover', styles.cover)}>
            <p><strong>플라즈마 멸균기</strong><br /><br />
              길튼치과는 환자 입에 닿는 모든 기구를<br />
              사용 후 소독합니다.</p>
          </div>
        </div>
        <div className={classnames('content', 'narrow', styles.content, styles.narrow)}>
          <dl>
            <dd className={styles.thumb}><img src={'images/speciality/prevention-1.jpg'} alt={'prevention 1'} /></dd>
            <dt>01</dt>
            <dd>
              <p>원내 환기 시스템과 김상환 원장이 직접 개발한 치료 중 발생하는 비말을 제거하는
                장치 (aerosol terminator)를 갖추어 공기 중으로의 감염을 원천적으로 차단합니다.</p>
            </dd>
            <dd className={styles.thumb}><img src={'images/speciality/prevention-2.jpg'} alt={'prevention 2'} /></dd>
            <dt>02</dt>
            <dd>
              <p>최신 고압멸균기, 플라즈마 멸균기, 초음파 소독기, UV 소독기, 핸드피소 소독기 등을
                갖추어 모든 기구와 장비를 철저하게 멸균 소독 합니다.</p>
            </dd>
            <dd className={styles.thumb}><img src={'images/speciality/prevention-3.jpg'} alt={'prevention 3'} /></dd>
            <dt>03</dt>
            <dd>
              <p>국제적 소독 감염 예방 (OSHA Regulation)의 엄격한 기준을 적용하여 한번 사용한 기구와 장비 뿐 아니라 공간도 완벽하게 소독하는 1인 1기구 1공간을 추구합니다.</p>
            </dd>
            <dd className={styles.thumb}><img src={'images/speciality/prevention-4.jpg'} alt={'prevention 4'} /></dd>
            <dt>04</dt>
            <dd>
              <p>길튼치과는 치아염소산(HOCL)으로 살균한 안전한 "진료수"를 사용하여 한차원 높은
                감염관리를 추구합니다. 치아염소산(HOCL)은 내성과 독성이 없이 바이러스 및 각종
                유해세균 사멸에 탁월한 효과를 지닌 물질입니다. 치아염소산 진료수는 인체 자극과
                환경에 대한 걱정이 없는 현존하는 가장 이상적인 살균소독수 입니다.</p>
            </dd>
          </dl>
        </div>
      </section>
      <section className={classnames('pc', styles.band)}>
        <img src={'images/speciality-2.jpg'} alt={'Premiere Promise'} />
        <p>최첨단 디지털 장비로 최고의 의료서비스를 제공하겠습니다.</p>
      </section>
      <section id="premiere" className={classnames('white', styles['speciality-premiere'])}>
        <div className={classnames('content', styles.content)}>
          <h2 className={classnames('line-hg', styles['line-hg'])}>Premiere</h2>
          <img src={'images/speciality/premiere.jpg'} alt="Premiere" className={classnames('mobile', styles.hg)} />
        </div>
        <div className={classnames('content', 'narrow', styles.content, styles.narrow)}>
          <img src={'images/speciality/premiere-1.jpg'} alt={'Premiere 1'} className={classnames('pc')} />
          <dl>
            <dt>01</dt>
            <dd>
              <p>3D 구강스캐너 Trios, 3D 안면스캐너 Morpheus,
                3D 프린터를 도입해 임플란트와 교정치료를 하는
                최신 디지털 치과입니다.</p>
            </dd>
          </dl>
          <span className="mobile btn btn-fill" onClick={() => setShowTrio(!showTrio)}>Show {more(showTrio)}</span>
        </div>
        {showTrio && <Trios />}
        <div className={classnames('content', 'narrow', styles.content, styles.narrow)}>
          <img src={'images/speciality/premiere-2.jpg'} alt={'Premiere 2'} className={classnames('pc')} />
          <dl>
            <dt>02</dt>
            <dd>
              <p>임플란트 표면처리 활성기기인 SQUVA를 사용하여 임플란트가 뼈와 결합하는 시간을 단축시킬 수 있으며 임플란트 성공률을 높일 수 있습니다.</p>
            </dd>
          </dl>
          <span className="mobile btn btn-fill" onClick={() => setShowSquva(!showSquva)}>Show {more(showSquva)}</span>
        </div>
        {showSquva && <Squva />}
        <div className={classnames('content', 'narrow', styles.content, styles.narrow)}>
          <img src={'images/speciality/premiere-3.jpg'} alt={'Premiere 3'} className={classnames('pc')} />
          <dl>
            <dt>03</dt>
            <dd>
              <p>EMS Airflow를 사용하여 나노 입자 파우더를 뿌려 기존 스케일러로 제거할 수 없는 치아 틈이나 치아의 미세한 구멍에 낀 각종 착색과 치태를 깨끗이 청소할 수 있습니다.</p>
            </dd>
          </dl>
          <span className="mobile btn btn-fill" onClick={() => setShowEms(!showEms)}>Show {more(showEms)}</span>
        </div>
        {showEms && <Ems />}
        <div className={classnames('content', 'narrow', styles.content, styles.narrow)}>
          <img src={'images/speciality/premiere-4.jpg'} alt={'Premiere 4'} className={classnames('pc')} />
          <dl>
            <dt>04</dt>
            <dd>
              <p>길튼치과는 환자분들의 통증과 불안을 최소화하여 아프지 않은 치과, 편안한 치과가 되기 위해
                최선을 다하고 있습니다.</p>
            </dd>
          </dl>
          <span className="mobile btn btn-fill" onClick={() => setShowMedi(!showMedi)}>Show {more(showMedi)}</span>
        </div>
        {showMedi && <Medi />}
        <div className={classnames('content', 'narrow', 'pc', styles.content, styles.narrow)}>
          <img src={'images/speciality/premiere-5.jpg'} alt={'Premiere 5'} className={classnames('pc')} />
          <dl>
            <dt>05</dt>
            <dd>
              <p>근거리 대학 병원과의 협력을 통하여 수준 높은 진료를 하고 있습니다.</p>
            </dd>
          </dl>
        </div>
      </section>
      <section className={classnames('mobile', styles.cooperation)}>
        근거리 대학병원과의 협력을 통하여<br />
        수준 높은 진료를 하고 있습니다.
      </section>
    </main>
  )
}
