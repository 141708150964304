import { Breadcrumb } from '../components/Breadcrumb'
import styles from '../styles/Team.module.css'
import classnames from 'classnames'

export default function Team () {
  return (
    <main className="Content">
      <section className={styles.welcome}>
        <img src={'images/team.jpg'} alt={'대표원장'} className="mobile" />
        <img src={'images/team-pc.jpg'} alt={'대표원장'} className="pc" />
        <div className={classnames('cover', styles.cover)}>
          <em>About Us</em>
          <h2>대표원장 인사말</h2>
          <p>반갑습니다.<br />
            길튼치과 대표원장 김상환입니다.<br />
            길튼치과의 공간은 제가 졸업한<br />
            유치원이 있던 자리입니다.<br />
            제가 태어나고 성장한 지역에서 지역주민들의<br />
            치아 건강을 위해 봉사할 수 있어서 감사한 마음입니다.<br />
            믿고 맡길 수 있는 평생 치아 주치의가 될 수 있도록<br />
            방문하시는 모든 환자분들께 정성을<br />
            다해 진료하겠습니다.<br />
            길튼치과 홈페이지 방문을 감사드립니다.
          </p>
          <p>길튼치과 대표원장<br />
            김 상 환 올림</p>
          <img src={'signature.png'} alt="signature" className={styles.signature} />
        </div>
      </section>
      <Breadcrumb activeIndex={2} />
      <section className={styles.mohw}>
        <img src={'mohw.png'} alt="보건복지부" />
        <p>길튼치과 의료진은 보건복지부 인증 치과전문의 입니다.</p>
      </section>
      <div className={styles.container}>
        <section className={styles.members}>
          <div className={classnames('content', styles.content)}>
            <p className={styles.introduce}>여러분의 평생 구강 건강주치의 <br className="mobile" />
              길튼치과 의료진을 소개합니다.</p>
          </div>
          <div className={classnames('content', styles.content)}>
            <em>대표원장</em>
            <h2>김상환</h2>
          </div>
          <img src={'images/profile-head.jpg'} alt="대표원장 김상환" className="mobile" />
        </section>
        <img src={'images/profile-head-pc.jpg'} alt="대표원장 김상환" className={classnames('pc', styles.profile)} />
        <section className={classnames('white', styles.history)}>
          <div className={classnames('content', styles.content)}>
            <ul className="indent">
              <li>
                <p>보건복지부인증 통합치의학과 전문의</p>
              </li>
              <li>
                <p>대원외국어고등학교 졸업</p>
              </li>
              <li>
                <p>단국대학교 치과대학 졸업</p>
              </li>
              <li>
                <p>미국 UCLA 치과대학 보철 & 임플란트학 전공</p>
                <p className={styles.english}>Advanced Prosthodontics & Implantology, Advanced Clinical Training,
                  UCLA</p>
              </li>
              <li>
                <p>미국 UCLA 치과대학 교정과 연수</p>
                <p className={styles.english}>Section of Orthodontics, Externship, UCLA</p>
              </li>
              <li>
                <p>미국 하버드 대학교 치과보존과 연수</p>
                <p className={styles.english}>Restorative Dentistry and Biomaterials Sciences, Externship, Harvard
                  School
                  of Dental
                  Medicine</p>
              </li>
              <li>
                <p>미국 펜실베니아 대학교 근관치료 과정 수료 </p>
                <p className={styles.english}>Endodontics, Course Completed, Penn Dental Medicine</p>
              </li>
              <li>
                <p>미국 치과의사 면허 (NBDE Part I, II) 합격</p>
                <p className={styles.english}>National Board of Dental Examination Part I, II</p>
              </li>
              <li>
                <p>용산 121 미군종합병원 Externship</p>
                <p className={styles.english}>121 Medical Hospital, Externship, USAG</p>
              </li>
              <li>
                <p>미국 치과보철학회 회원</p>
              </li>
              <li>
                <p>대한 심미치과학회 회원</p>
              </li>
              <li>
                <p>대한 설측교정학회 회원</p>
              </li>
              <li>
                <p>대한 디지털교정치과의사회 창립회원</p>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </main>
  )
}
