import { useState } from "react";
import _ from 'lodash';
import { HashLink } from "react-router-hash-link";
import styles from '../styles/Navigation.module.css';

export const Navigation = (props) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const Link = (route, label) => <HashLink to={route} onClick={props.onClick}>{label}</HashLink>;

  const navContents = _.map(props.menu, (o, i) => {
    let subClass = styles.inactive;
    if (activeIndex === i) {
      subClass = styles.sub;
    }

    let link = o.route ? Link(o.route, o.label) :
      <span onClick={() => setActiveIndex(activeIndex === i ? -1 : i)}>{o.label}</span>;

    return <li key={i}>{link}
      {o.children && _.size(o.children) > 0 && <ul className={subClass}>
        {_.map(o.children, (child, k) =>
          <li key={k}>{Link(child.route, child.label)}</li>
        )}</ul>}
    </li>;
  });

  return <div className={styles.navigation}>
    <nav className={styles.sidebar}>
      <ul className={styles.main}>
        {navContents}
      </ul>
    </nav>
  </div>
};
