import styles from '../styles/Footer.module.css'

export const Footer = () => <footer className={styles.footer}>
  <h1 className={styles.logo}><img src={'logo-bottom.png'} alt={'Gilton Dental Clinic'} width={187} height={72} /></h1>
  <h2 className={styles.title}><span>CONTACT US</span></h2>
  <address className={styles.address}>
    <ul className={styles['contact-mobile']}>
      <li>TEL. <a href="tel:02-585-8050">02-585-8050</a></li>
      <li>FAX. 02-585-8051</li>
    </ul>
    <ul className={styles.info}>
      <li>사업자등록번호 : 229-18-04086</li>
      <li>사업자명 : 길튼치과의원</li>
      <li>대표자 : 김상환</li>
      <li>주소 : 서울특별시 서초구 서운로34 1층</li>
      <li><a href="mailto:giltondent@naver.com">giltondent@naver.com</a></li>
    </ul>
    <div className={styles.contact}>
      <ul>
        <li>
          <a href="tel:02-585-8050" className={styles.phone}>02.585.8050</a>
          <span className={styles.ask}>예약 및 전화상담 문의</span>
        </li>
        <li><span className={styles.kakao}>KAKAOTALK : @giltondent</span></li>
      </ul>
    </div>
  </address>
  <img src={'logo-footer.png'} alt="" className={styles.emblem} />
</footer>
