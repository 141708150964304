import { Breadcrumb } from '../components/Breadcrumb'
import Slider from 'react-slick'
import styles from '../styles/Promise.module.css'
import classnames from 'classnames'

const config = {
  dots: true,
  layLoad: true,
  infinite: true,
  speed: 1000,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 2500,
  slidesToShow: 1,
  slidesToScroll: 1
}

export default function Promise () {
  return (
    <main className="Content">
      <section className={styles.bi}>
        <Slider {...config} className="mobile">
          <div className={classnames(styles['each-slide'], styles['bi-slide-01'])}>
            <img src={'images/bi-01.jpg'} alt={'bi-01'} />
            <div className={classnames('cover', styles.cover)}>
              <img src={'./logo-big.png'} alt={'길튼치과'} className={styles.logo} />
              <p>길튼치과는 튼튼하고 트여진 길을 형상화한<br />
                한글의 조형적인 느낌과 ‘자연미의 현대적 해석’을<br />
                브랜딩 컨셉으로 디자인 되었습니다.</p>
            </div>
          </div>
          <div className={classnames(styles['each-slide'], styles['bi-slide-02'])}>
            <img src={'images/bi-02.jpg'} alt={'bi-02'} />
            <div className={classnames('cover', styles.cover)}>
              <h2>원칙적인 진료</h2>
              <p>“ 치과 치료의 올바른 길을 지향하고 안내하는 병원 ”<br />
                근거중심의 원칙적인 치료, 과잉진료 없는<br />
                꼭 필요한 진료만을 제공합니다.</p>
            </div>
          </div>
          <div className={classnames(styles['each-slide'], styles['bi-slide-03'])}>
            <img src={'images/bi-03.jpg'} alt={'bi-03'} />
            <div className={classnames('cover', styles.cover)}>
              <h2>보이지 않는 것에<br />
                가치를 두는 ‘진정성’</h2>
              <p>치료 결과 뿐 아니라 치료 과정을 상세히 설명하고 보여드립니다.<br />
                감염예방을 위해 철저한 소독을 하여 청결함을 유지합니다.<br />
                치료가 끝난 후에도 지속적인 사후관리를 해드립니다.<br />
                정직한 진료와 정성이 담긴 서비스를 제공합니다.</p>
            </div>
          </div>
          <div className={classnames(styles['each-slide'], styles['bi-slide-04'])}>
            <img src={'images/bi-04.jpg'} alt={'bi-04'} />
            <div className={classnames('cover', styles.cover)}>
              <h2>최고를 지향하는<br />
                ‘혁신성’</h2>
              <p>미국 명문 치과대학인 UCLA와 하버드의 선진 진료시스템을<br />
                도입하여 최고의 의료서비스를 제공합니다.</p>
            </div>
          </div>
        </Slider>
        <Slider {...config} className={classnames('pc', styles['bi-slide'])}>
          <div className={classnames(styles['each-slide'], styles['bi-slide-01'])}>
            <img src={'images/bi-pc-01.jpg'} alt={'bi-01'} className={styles['cover-image']} />
            <div className={classnames('cover', styles.cover)}>
              <img src={'./logo-big.png'} alt={'길튼치과'} className={styles.logo} />
              <p>길튼치과는 튼튼하고 트여진 길을 형상화한<br />
                한글의 조형적인 느낌과 ‘자연미의 현대적 해석’을<br />
                브랜딩 컨셉으로 디자인 되었습니다.</p>
            </div>
          </div>
          <div className={classnames(styles['each-slide'], styles['bi-slide-02'])}>
            <img src={'images/bi-pc-02.jpg'} alt={'bi-02'} className={styles['cover-image']} />
            <div className={classnames('cover', styles.cover)}>
              <h2>원칙적인 진료</h2>
              <p>“ 치과 치료의 올바른 길을 지향하고 안내하는 병원 ”<br />
                근거중심의 원칙적인 치료, 과잉진료 없는<br />
                꼭 필요한 진료만을 제공합니다.</p>
            </div>
          </div>
          <div className={classnames(styles['each-slide'], styles['bi-slide-03'])}>
            <img src={'images/bi-pc-03.jpg'} alt={'bi-03'} className={styles['cover-image']} />
            <div className={classnames('cover', styles.cover)}>
              <h2>보이지 않는 것에<br />
                가치를 두는 ‘진정성’</h2>
              <p>치료 결과 뿐 아니라 치료 과정을 상세히 설명하고 보여드립니다.<br />
                감염예방을 위해 철저한 소독을 하여 청결함을 유지합니다.<br />
                치료가 끝난 후에도 지속적인 사후관리를 해드립니다.<br />
                정직한 진료와 정성이 담긴 서비스를 제공합니다.</p>
            </div>
          </div>
          <div className={classnames(styles['each-slide'], styles['bi-slide-04'])}>
            <img src={'images/bi-pc-04.jpg'} alt={'bi-04'} className={styles['cover-image']} />
            <div className={classnames('cover', styles.cover)}>
              <h2>최고를 지향하는<br />
                ‘혁신성’</h2>
              <p>미국 명문 치과대학인 UCLA와 하버드의 선진 진료시스템을<br />
                도입하여 최고의 의료서비스를 제공합니다.</p>
            </div>
          </div>
        </Slider>
      </section>
      <Breadcrumb activeIndex={0} />
      <section className={classnames('mobile', styles.slogan)}>
        <img src={'logo-text.png'} alt={'Gilton Dental Clinic'} className={styles['logo-text']} />
        <p>꾸준한 예방관리를 통한<br />
          평생 구강건강 주치의가 되겠습니다.</p>
      </section>
      <section className={classnames('mobile', styles['promise-message'], 'white')}>
        <img src={'images/promise/promise-0.jpg'} alt="" />
        <div className={classnames('cover', styles.cover)}>
          <h2>구강건강의<br />
            평생 주치의</h2>
          <p>원칙을 지키는<br />
            최고 수준의 꼼꼼한 진료,<br />
            꾸준한 예방관리를 통해 고객이<br />
            믿고 맡길 수 있는<br />
            평생 구강건강 주치의가 되겠습니다.</p>
        </div>
      </section>
      <section className={classnames('pc', styles['promise-message'])}>
        <img src={'images/promise/0.jpg'} alt="" />
        <div className={classnames('cover', styles.cover)}>
          <h2>구강건강의 평생 주치의</h2>
          <p>정직하고 꼼꼼한 진료, 꾸준한 예방관리를 통해<br />
            고객이 믿고 맡길 수 있는 평생 구강건강 주치의가 되겠습니다.</p>
        </div>
      </section>
      <section className={classnames('pc', styles.announce)}>
        <img src={'images/promise/bg.jpg'} alt="" />
        <div className={classnames('cover', styles.cover)}>
          <em>Gilton’s Promise</em>
          <h2>길튼치과의 약속</h2>
          <p>근거중심의 원칙적인 치료,<br />
            과잉진료 없는 꼭 필요한 진료만을<br />
            제공하겠습니다.</p>
        </div>
      </section>
      <section className={styles.promise}>
        <div className="content">
          <p className={classnames('mobile', 'hg', styles.hg)}>Gilton’s Promise<br />
            길튼치과의 약속</p>
          <div className={styles.container}>
            <div className={classnames('pc', styles.overview)}>
              <h3>가족을 치료하는 마음으로<br />
                정성을 다하여 진료합니다.</h3>
              <p>'내 가족이어도 이렇게 진단하고 치료할까?'<br />
                길튼치과 의료진은 처음 환자분을 뵙고<br />
                진단을 하고 치료 계획을 세울 때에도<br />
                진료의 시작과 끝 전 과정에서도<br />
                그리고 모든 진료가 끝난 후에도<br />
                항상 이 질문을 기반으로 정확하게 진단하고,<br />
                꼼꼼한 진료과 꾸준한 예방관리를 해드리는 것을<br />
                진료철학으로 삼고 있습니다.</p>
            </div>
            <dl className={styles.desc}>
              <dt>
                <img src={'images/promise/promise-1.jpg'} alt="01" />
                01
              </dt>
              <dd>
                <p>고객 한분, 한분 모두 가족처럼 생각하는 마음으로
                  성심성의껏 진료하겠습니다.</p>
              </dd>
              <dt>
                <img src={'images/promise/promise-2.jpg'} alt="02" />
                02
              </dt>
              <dd>
                <p>철저한 소독과 엄격한 위생관리로 청결함을 유지하겠습니다.</p>
              </dd>
              <dt>
                <img src={'images/promise/promise-3.jpg'} alt="03" />
                03
              </dt>
              <dd>
                <p>믿고 맡길 수 있도록 꼭 필요한 치료만 권하겠습니다.</p>
              </dd>
              <dt>
                <img src={'images/promise/promise-4.jpg'} alt="04" />
                04
              </dt>
              <dd>
                <p>작은 고민도 경청하고 공감하며 배려하는 마음으로
                  진료하겠습니다.</p>
              </dd>
              <dt>
                <img src={'images/promise/promise-5.jpg'} alt="05" />
                05
              </dt>
              <dd>
                <p>지속적인 발전을 위해 늘 공부하고 연구하겠습니다.</p>
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </main>
  )
}
