import styles from '../../styles/SpecialityDetails.module.css'
import classnames from 'classnames'

export default function Squva () {
  return (
    <div className={classnames('mobile', styles.detail)}>
      <img src={'images/speciality/06-3-squova.jpg'} alt={'Squva'} />
      <div className={classnames('cover', styles.cover)}>
        <h2>Squva</h2>
      </div>
      <div className={classnames('content', styles.content)}>
        <div className={classnames('box', styles.box)}>
          <span>Squva 스쿠바</span>
          <p>현재 일반적으로 쓰이는 SLA 표면 임플란트는 시간이 갈수록 공기 속 탄화수소 등 유기물이 부착되면서 생물학적 노화현상
            (Biologic Aging)이 발생한다. 임플란트를 제작한 즉시 환자에게 식립하면 표면이 깨끗하고 친수성 (물에 잘 젖는) 이기 때문에 뼈와 빨리 잘 붙지만 현실적으로 불가능합니다.
            치과에서
            보관한 시간이 길수록 임플란트 표면은 오염되어 성공률도 떨어지고 뼈와 붙는 시간도 오래 걸리므로 스쿠바는 임플란트 표면에 UV를 조사해 임플란트 표면의 유기물이나 불순물을 제거하고 표면을
            초신수성(Super-Hydrophic)으로 활성화한다. 임플란트 제작 즉시 상태로 돌려주어 최상의 상태로 환자에게 식립 가능합니다.</p>
        </div>
      </div>
    </div>
  )
}
