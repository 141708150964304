import './styles/App.css';
import { Switch, Route, BrowserRouter } from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top';
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import Home from "./pages/Home";
import Speciality from "./pages/Speciality";
import Space from "./pages/Space";
import Opening from "./pages/Opening";
import Promise from "./pages/Promise";
import Team from "./pages/Team";

const menu = [{
  'label': '길튼치과 소개',
  'children': [{
    'label': 'Brand Identity',
    'route': '/'
  }, {
    'label': '길튼치과의 약속',
    'route': '/promise'
  }, {
    'label': '치과둘러보기',
    'route': '/space'
  }]
}, {
  'label': '길튼치과의 특별함',
  'route': '/speciality'
}, {
  'label': '의료진 소개',
  'route': '/team'
}, {
  'label': '진료시간 & 오시는 길',
  'route': '/opening'
}];

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Header menu={menu} />
          <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/promise" component={Promise} />
            <Route path="/space" component={Space} />
            <Route path="/team" component={Team} />
            <Route path="/speciality" component={Speciality} />
            <Route path="/opening" component={Opening} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
