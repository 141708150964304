import { ImageSlide } from '../../components/ImageSlide'
import classnames from 'classnames'
import styles from '../../styles/SpecialityDetails.module.css'

const image1 = ['images/speciality/trios1/1.png',
  'images/speciality/trios1/2.png',
  'images/speciality/trios1/3.png',
  'images/speciality/trios1/4.png',
  'images/speciality/trios1/5.png',
  'images/speciality/trios1/6.png']

const image2 = ['images/speciality/trios1/1.png',
  'images/speciality/trios2/2.png',
  'images/speciality/trios2/3.png',
  'images/speciality/trios2/4.png']

const image3 = ['images/speciality/3d/1.png',
  'images/speciality/3d/2.png',
  'images/speciality/3d/3.png',
  'images/speciality/3d/4.png',
  'images/speciality/3d/5.png']

export default function Trios () {
  return (
    <div className={classnames('mobile', styles.detail)}>
      <div className={styles.dummy}>
        <img src={'images/speciality/detail-11.jpg'} alt={'Trios'} />
        <div className={classnames('cover', styles.cover)}>
          <h3 className={classnames('line-v', styles['line-v'], 'dark')}>
            3D 구강스캐너 Trios,<br />
            3D 안면스캐너 Morpheus 3D,<br />
            3D 프린터</h3>
        </div>
        <div className={classnames('cover', styles.cover, styles.bottom)}>
          <p>3D 구강스캐너 Trios, 3D 안면스캐너 Morpheus 3D, 3D 프린터를 도입해 정확한 임플란트 수술, 이상적인 보철과
            교정치료 제공하는 최신 디지털치과입니다.</p>
        </div>
      </div>
      <div className={classnames('content', styles.content)}>
        <h2>Trios</h2>
        <div className={classnames('box', styles.box)}>
          <span>3D 구강스캐너 Trios</span>
          <p>세계1위 구강스캐너 제조사인 덴마크 3Shape사의 Trios는 뛰어난 광학 기술로 초당 3000장 이상의 구강 이미지를 촬영해
            3D로 재구성하는 첨단 디지털 장비입니다. Trios를 이용하면 1분 이내에 구강 전체를 스캔하여 3차원 이미지를 획득할 수 있을
            뿐 아니라 잇몸과 치아의 색상도 판단해 정확한 색상정보를
            제공합니다. 전통 방식의 고무 인상재를 통한 치아 본뜨기 과정은 오랜 시간 동안 환자가 인상재를 물고 있어야 한다는 불편함은
            물론이고 인상재가 경화되기 전 미세한 움직임이 있으면 변형이
            일어나기 쉬워 정확한 보철물을 제작하는데 한계가 있습니다.
            Trios는 이러한 고무 인상재의 단점을 모두 극복한 첨단 인상채득 장치로 인상재가 입안에 들어가지 않아 편하고 빠르고 정밀한
            인상채득이 가능합니다. 길튼치과는 Trios로 얻은 3차원 구강 정보를 활용하여 이상적인 위치에 임플란트를 식립하고 오차를
            최소화한 자연스럽고 정밀한 보철물을 제작하고 있습니다.
            또한 스캔한 기존의 자연치아 형태를 보철물 제작에 할용하여 충치치료, 신경치료, 임플란트 치료 후에 환자분이 가지고 있던 원래
            치아 모양에 가장 유사하게 복원이 가능합니다.</p>
        </div>

        <img src={'images/speciality/detail-13.png'} alt={'Trios Color Measurement'}
             className={styles['float-right']} />
        <h3 className={classnames('line-v', styles['line-v'])}>Trios Color Measurement</h3>
        <p>˙길튼치과는 Trios를 이용하여
          치아 고유의 정확한 색상정보를
          판단해 자연스럽고 아름다운
          보철물을 제작합니다.</p>

        <h3 className={classnames('line-v', styles['line-v'])}>Trios의 활용</h3>
        <p>˙Trios를 활용한 네비게이션 임플란트 수술</p>
        <ImageSlide images={image1} />
        <p>˙Trios를 활용한 맞춤 보철 치료</p>
        <ImageSlide images={image2} />
      </div>

      <div className={styles.dummy}>
        <img src={'images/speciality/detail-12.jpg'} alt={'Morpheus 3D'} />
        <div className={classnames('cover', styles.cover)}>
          <h2>Morpheus 3D</h2>
        </div>
      </div>
      <div className={classnames('content', styles.content)}>
        <div className={classnames('box', styles.box)}>
          <span>3D 안면스캐너 Morpheus 3D</span>
          <p>모르페우스 3D는 얼굴을 입체적으로 촬영하여 3차원 정보로 변환하는 첨단 디지털 장비입니다. 길튼치과는 Morpheus로 얻은
            3차원 얼굴 정보를 입체적으로 분석하여 환자 개인에 맞는
            이상적인 얼굴이 되도록 교정치료 계획을 수립하고, 시뮬레이션을 통해 치료결과를 미리 확인합니다. 시뮬레이션 결과를 치료계획에
            반영하여 실제 치료결과와의 오차를 최대한 줄입니다.</p>
        </div>
        <h3 className={classnames('line-v', styles['line-v'])}>Morpheus 3D를 이용한 교정치료</h3>
        <p>˙Morpheus 3D를 이용한 교정치료 계획 과정</p>
        <ImageSlide images={image3} />
        <p>˙정밀 교정장치 부착 시스템</p>
      </div>
    </div>
  )
}
