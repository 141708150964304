import styles from '../styles/Booking.module.css'

export const Booking = () => <ul className={styles.booking}>
  <li><a href="https://m.booking.naver.com/booking/13/bizes/514012" target="_blank" rel="noreferrer"
         className={styles['icon-naver']}>네이버 예약하기</a></li>
  <li><a href="https://pf.kakao.com/_elxfyK" target="_blank" rel="noreferrer"
         className={styles['icon-kakao']}>카카오톡 예약하기</a>
  </li>
  <li><a href="tel:02-585-8050" className={styles['icon-phone']}>전화 예약하기</a></li>
</ul>
