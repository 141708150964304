import { Breadcrumb } from '../components/Breadcrumb'
import { ImageSlide } from '../components/ImageSlide'
import styles from '../styles/Space.module.css'
import classnames from 'classnames'

const mainImages = [
  'images/space-01.jpg',
  'images/space-02.jpg',
  'images/space-03.jpg',
  'images/space-04.jpg',
  'images/space-05.jpg'
]

const mainBigImages = [
  'images/space-pc-01.jpg',
  'images/space-pc-02.jpg',
  'images/space-pc-03.jpg',
  'images/space-pc-04.jpg',
  'images/space-pc-05.jpg'
]

const spaceImage = []
for (let i = 1; i <= 23; ++i) {
  spaceImage.push(`images/tours/${i}.jpg`)
}

export default function Space () {
  return (
    <main className="Content">
      <section className={styles.space}>
        <ImageSlide images={mainImages} autoplay className='mobile' />
        <ImageSlide images={mainBigImages} autoplay className='pc' />
        <div className={classnames('cover', styles.cover)}>
          <em>Gilton's Space</em>
          <h1>치과 둘러보기</h1>
          <p>‘치과는 무섭다’는 고정관념을 깨고<br />
            오시는 고객이 편안하게 진료받고 돌아가실 수<br />
            있도록 따듯하고 자연친화적인 공간을 제공합니다.</p>
        </div>
      </section>
      <Breadcrumb activeIndex={1} />
      <section className={classnames('white', styles.container)}>
        <div className={classnames('content', styles.content)}>
          <p className={'hg'}>길튼치과의 공간은 자연스러운 배려가 있는<br />
            ‘Urban Retreatment’의 컨셉으로 디자인 되었습니다.<br />
            일상 속에서 자연으로 휴식과 치유를 하듯,<br />
            건강하고 편안한 감성적 힐링이 될 수 있었으면 합니다.</p>
        </div>
        <div className={styles['space-slider']}>
          <ImageSlide images={spaceImage} arrows adaptiveHeight speed={750} />
        </div>
      </section>
    </main>
  )
}
